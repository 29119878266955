import { $t, t_key } from "../plugins/i18n";
import { RegistrationData } from "models/registration_data";
import { ProfileData } from "models/profile_data";
import useBreakpoint from "../plugins/breakpoint";

export type ProfileComponentProps = {
  formData: RegistrationData | ProfileData,
  isRegister: boolean
  isParent: boolean
}

const ProfileComponent = (props: ProfileComponentProps) => {
  const isMobile = useBreakpoint();

  const getName = () => {
    return props.isParent 
      ? `${props.formData.parent_first_name} ${props.formData.parent_last_name}`
      : props.formData.full_name;
  }

  const getPersonCode = () => {
    return props.isParent ? props.formData.parent_person_code : props.formData.person_code;
  }

  const showLocalGov = (): boolean => {
    if (props.formData.field_values.hasOwnProperty("local_gov")) {
      return true;
    }
    return false;
  }

  const renderMobileView = (): JSX.Element => {
    const localGovText = `(${$t(t_key.profile_view.on_date)} ${props.formData.local_gov_date}): ${props.formData.local_gov ?? "-"}`
    const profileText = `${getName()} (${getPersonCode()})`

    return (
      <div>
        <section className="profile-section-mobile">
          <div className="profile-icon" aria-hidden>
            <img className="profile-img" alt="Profile" aria-hidden/>
          </div>
          <div className="profile-info-name-mobile">
            {profileText}
          </div>  
        </section>

        {!props.isRegister &&
          <section className="profile-sub-section-mobile mt-3">
            {props.formData.identities != null ? 
              <div>
                <strong>
                  {props.formData.identities.length == 1 
                    ? $t(t_key.profile_view.username)
                    : $t(t_key.profile_view.usernames)
                  }
                </strong>: { props.formData.identities.join(", ") }
              </div> 
              : null
            }
          </section>
        }
        { showLocalGov() &&
          <div className="profile-info-gov mt-3">
            <strong>{$t(t_key.profile_view.local_gov)}</strong> {localGovText}
          </div>
        }

      </div>
    );

  }

  const localGovText = `${$t(t_key.profile_view.local_gov)} (${$t(t_key.profile_view.on_date)} ${props.formData.local_gov_date}): ${props.formData.local_gov ?? "-"}`
  const profileText = `${getName()} (${getPersonCode()})`
  return (
    <>
      {isMobile 
        ? renderMobileView()
        : (
          <div className="profile" tabIndex={-1}>
            <div className="profile-icon" aria-hidden>
              <img className="profile-img" alt="Profile" aria-hidden />
            </div>
            <div className="profile-info">
              <div className="profile-info-name">
                {profileText}
              </div>  

              {!props.isRegister &&
                <div className="prop">
                  {props.formData.identities != null ? 
                    <div className="prop">
                      {props.formData.identities.length == 1 
                        ? $t(t_key.profile_view.username) 
                        : $t(t_key.profile_view.usernames)}: {props.formData.identities.join(", ")
                      }
                    </div> 
                    : null
                  }
                </div>
              }

              { showLocalGov() &&
                <div className="profile-info-gov">
                  {localGovText}
                </div>
              }
            </div>
          </div>
        )
      }
    </>
  )
}

export default ProfileComponent;
